import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';

const PromoModal: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(true);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <>
      <Modal
        fade
        backdrop
        isOpen={modalOpen}
        toggle={toggleModal}
        className="d-flex justify-content-center align-items-center flex-column h-100 promo-modal"
      >
        <ModalBody className="d-flex flex-column align-items-center">
          <img
            src={require('./assets/images/Cayemo_Logo_black.png')}
            alt="cayemo logo"
            className="img-fluid w-25 mb-3 mt-2"
          />
          <h2>newsletter</h2>
          <img
            src={require('./assets/images/modal_image.jpg')}
            alt="cayemo Newsletter"
            className="img-fluid w-100 mb-3 mt-2"
          />
          <p>
            trag dich in den newsletter ein, um nichts zu verpassen! als dankeschön bekommst du 10% rabatt auf deine
            nächste bestellung
          </p>
          <Link to="/newsletter">
            <button className="btn-lg btn btn-primary" onClick={toggleModal}>
              Jetzt für den Newsletter anmelden
            </button>
          </Link>
        </ModalBody>
      </Modal>
      {children}
    </>
  );
};

export default PromoModal;
